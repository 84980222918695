import Modal from "./Modal";
import React from "react";
import AppContext from "../contexts/AppContext";
import i18n from "../i18n";

export default class DownloadModal extends Modal {

  constructor(props) {
    super(props);
    this.className = ""; // todo
  }

  handleCloseButtonClick = (e) => {
    super.handleCloseButtonClick(e);
  }

  renderModal() {
    return <div className="modal-container">
      <div className="modal">
        <h3 dangerouslySetInnerHTML={{__html: i18n.t("download_modal_heading")}} />
        <p dangerouslySetInnerHTML={{__html: i18n.t("download_modal_text")}}></p>
        <button
          className="creative-download"
          children={i18n.t("download_modal_button")}
          onClick={this.handleCloseButtonClick} />
      </div>
    </div>
  }
}

DownloadModal.contextType = AppContext;
