function fill(amount, value) {
  const array = [];

  for (let i = 0; i < amount; i++) {
    array.push(typeof value === "function" ? value(i, array) : value);
  }

  return array;
}

export default {
  fill,
}