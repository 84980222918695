module.exports = {
    "index_heading": "Let AI create your perfect Halloween&nbsp;art. Easily.",
    "index_text_1": "Give AI a text description of an image and just wait for it!",
    "index_text_2": "Keep it simple, describe your idea in a short phrase.",
    "index_generate_button_1": "Let’s BOO it!",
    "index_generate_button_2": "Generate",

    "term_placeholder": "Type here",
    "term_error_required": "required",

    "btn_generate": "Let’s BOO it!",
    "btn_proceed": "proceed",
    "btn_back": "back",
    "btn_try_another_photo": "change photo",
    "btn_choose_photo": "choose photo",
    "btn_skip": "skip",
    "btn_start_again": "start again",

    "time_next_try": "⏰ try again in {{time}}",

    "download_modal_heading": "Let’s keep the BOO rolling!",
    "download_modal_text": "Share your ingenious Halloween Art in socials using the <span class='tag'>#ArtBotAI</span> hashtag and challenge your friends to come up with theirs.",
    "download_modal_button": "GOT IT",

    "upload_selfie_modal_text": "Upload your selfie so we can personalize your artwork",
    "upload_selfie_modal_share_btn": "save & share without selfie",
    "upload_selfie_modal_choose_photo_btn": "choose photo",
    "upload_selfie_modal_skip_btn": "skip",

    "upload_selfie_modal_change_selfie_message_btn": "change selfie message",
    "upload_selfie_modal_change_chat_avatar_btn": "change chat avatar",

    "tooltip_change_selfie": "Change selfie",
    "tooltip_change_selfie_without_file": "Upload selfie to get better results",

    "creative_dummy_text": "Upload selfie to get better results",

    "rules_list_title": "The rules are simple:",
    "rules_list_item1": "Select the profile picture you like most in your current state of mind",
    "rules_list_item2": "and <span>return a week later</span> for new effects, new options, and new picture.",

    "processing_tip": "Tip {{item}}/{{total}}",
    "processing_text_1": "Something BOO-tiful is on its way 👻",
    "processing_text_2": "This art is gonna be 100% unique! 👌",
    "processing_text_3": "Nobody else will get the same results! 🔮",
    "processing_text_4": "Like-minded people see different artworks 🔥",

    "processing_text": "Processing",
    "processing_upload_text": "Upload your selfie so we can personalize your artwork",

    "result__creative_download": "Save image",
    "result__creative_retry": "Retry",
    "result__creative_failed_message": "Oops, something went wrong with this style. 😔 Choose any other style or try again.",
    "result__select_result": "Please select the result you like the most",

    "result__creative_art_selfie": "How do you feel about artwork you've generated?",
    "result__creative_make_emotional_selfie": "Express it in a selfie! Just make emotional selfie and we'll create a cool picture to share!",

    "rateapp_dialog__title": "Love <span>#NewProfilePic</span>?",
    "rateapp_dialog__message": "Give stars to the app & help other users discover and love it too!",
    "rateapp_dialog__rate_button": "Rate!",
    "rateapp_dialog__skip_button": "Skip",

    "continue_with_app__title": "Love <span>#NewProfilePic</span>?",
    "continue_with_app__message": "To keep creating profile pics please get the app",
    "continue_with_app__continue_button": "Get the app",
    "continue_with_app__dismiss_button": "Skip",

    "error__error": "Error",

    "remove_logo_modal__title": "To keep or not to keep… 🤔",
    "remove_logo_modal__message": "Hey, did you know that the logo is a part of this effect’s design? It’ll make us happy if you choose to keep it.",
    "remove_logo_modal__btn_cancel": "Keep the logo",
    "remove_logo_modal__btn_remove": "Remove anyway",

    "toggle_watermark_tooltip__text": "Tap the logo to remove it",
    "toggle_watermark_on": "Logo on",
    "toggle_watermark_off": "Logo off",

    "internal_error": "An error occurred...",
    "error__default_message": "Something went wrong. Try again.",
    "error__network_message": "Please check your network connection.",
    "error__api_code_1": "Internal error",
    "error__api_code_2": "Incorrect params",
    "error__api_code_3": "Processing failure",
    "error__api_code_401": "Unauthorized request",
    "error__api_code_404": "Not found",
    "error__api_code_410": "The requested content is deleted",
    "error__api_code_415": "Sorry, this file format is not supported. Please upload an image file (JPEG or PNG).",

    "error_overload": "We are terrible sorry, but our servers experience too much of a load currently due to skyrocketing app downloads. Please, come back a little later. We are working hard to fix everything ASAP.",

    "contact_us": "Contact Us",
    "privacy_policy": "Privacy Policy",
    "terms": "Terms",
};

