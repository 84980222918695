import React from "react";
import routes from "../../routes";
import AppContext from "../../contexts/AppContext";
import {debounce} from "../../utils/etc";
import creativeGroups from "../../photolab/config/groups";
import TabView from "./TabView";
import TabContentView from "./TabContentView";
import {hitEvent, hits, logEvent, userEvents} from "../../utils/log";
import DownloadModal from "../../components/DownloadModal";
import RateAppModal from "../../components/RateAppModal";
import Processing from "../../photolab/Processing";
import {
  resolveCreativeImageFile,
  transformToDownloadUrl
} from "../../utils/creative";
import * as webviewUtils from "../../utils/webview";
import clientStorage from "../../utils/client-storage";
import {saveAs} from "file-saver";
import Creative from "../../photolab/Creative";
import processingManager from "../../photolab/ProcessingManager";
import {generatePath} from "react-router";
import {shareProvidersIds, webviewAnalyticsEvent} from "../../utils/webview";
import {goToProcessing} from "../../utils/url";
import i18n from "../../i18n";

export default class ResultPage extends React.Component {

  state = {
    isReady: false,
    tab: null,
    tabs: [],
  };

  processingChangedTimeoutId = null;
  hitProcessingFailedOnResultPageIsTriggered = false;

  componentDidMount() {
    this.tabsClicks = [];

    logEvent(userEvents.PAGE, {page: "result"});

    if (window.clientConfig.isWebview) {
      webviewUtils.webviewCheckInstalledApps((apps) => {
        this.context.setInstalledApps(apps);
      });
    }

    processingManager.addOnProcessingChangeHandler(this.handleProcessingChangedDebug);
    processingManager.addOnProcessingChangeHandler(this.handleProcessingChanged);

    if (processingManager.processing === null) {
      const restoredProcessing = processingManager.restore();
      if (restoredProcessing) {
        try {
          if (window.clientConfig.isPro) {
            restoredProcessing.creatives.forEach((creative) => {
              if (creative.getExtra(Creative.EXTRA_KEEP_PENDING)) {
                creative.removeExtra(Creative.EXTRA_KEEP_PENDING);
              }
            })
          }

          processingManager.start(restoredProcessing);
          this.setState({isReady: true});
        } catch (err) {
          console.error(err);

          this.props.history.replace(generatePath(routes.INDEX));
        }
      } else {
        this.props.history.replace(generatePath(routes.INDEX));
      }
    } else {
      this.setState({isReady: true});
      this.handleProcessingChanged();
    }

    const step = processingManager.processing.getExtra(Processing.EXTRA_STEP);
    if (step === Processing.STEP_LAYOUT) {
      webviewAnalyticsEvent("art_screen_shown",
        processingManager.processing.getExtra(Processing.EXTRA_TEXT, ""),
        "",
        "",
        processingManager.processing.getExtra(Creative.EXTRA_TEXT_APPEND, "")
      );
    } else if (step === Processing.STEP_RESULT || step === Processing.STEP_RESULT_WITHOUT_FILE) {
      webviewAnalyticsEvent("template_screen_shown",
        processingManager.processing.getExtra(Processing.EXTRA_TEXT),
        processingManager.processing.getExtra(Creative.EXTRA_AI_SEED),
        processingManager.processing.getExtra(Creative.EXTRA_TEXT_RESULT),
        processingManager.processing.getExtra(Creative.EXTRA_TEXT_APPEND, "")
      );
    }
  }

  componentWillUnmount() {
    processingManager.removeOnProcessingChangeHandler(this.handleProcessingChangedDebug);
    processingManager.removeOnProcessingChangeHandler(this.handleProcessingChanged);

    clearTimeout(this.processingChangedTimeoutId);
  }

  handleProcessingChangedDebug = () => {
    if (window.appConfig.isDebug) {
      const cloned = JSON.parse(processingManager.processing.toJSON(true));
      console.info("ResultPage::handleProcessingChanged", cloned);
    }
  };

  handleProcessingChanged = () => this.processingChangedTimeoutId = debounce("ResultPage_handleProcessingChanged", 100, () => {
    const processing = processingManager.processing;
    const isLayoutStep = processing.getExtra(Processing.EXTRA_STEP) === Processing.STEP_LAYOUT;

    const extraSelectedGroup = isLayoutStep
      ? Processing.SELECTED_LAYOUT_GROUP
      : Processing.SELECTED_RESULT_GROUP;

    const firstTab = processing.getExtra(extraSelectedGroup) || processing.groups[0];
    const firstTabCreatives = processing.getCreativesInGroup(firstTab);
    firstTabCreatives.first().setExtra(Creative.EXTRA_VIEW_OPENED, true);

    this.context.hideLoader();

    hitFirstTabClick(processing, firstTab, false);

    this.setState({
      tab: firstTab,
      tabs: [...processing.groups],
    });

    const selectedCreatives = processing.creatives
      .filter((c) => c.isSelected
        && c.group !== creativeGroups.COMMON
        && processing.groups.indexOf(c.group) > -1
      );

    const failedCreatives = selectedCreatives.filter((c) => c.isFailed);

    if (failedCreatives.length > 0 && !this.hitProcessingFailedOnResultPageIsTriggered) {
      this.hitProcessingFailedOnResultPageIsTriggered = true;
      hitEvent(hits.PROCESSING_FAILED_ON_RESULT_PAGE);
    }

    if (firstTabCreatives.first().isFailed) {
      if (!this.firstTabCreativeCommitFailed) {
        this.firstTabCreativeCommitFailed = true;
        processingManager.commitToApi(processing.toJSON());
      }
    }
  });

  handleTabClick = (tab) => {
    if (tab === this.state.tab) {
      return;
    }

    const processing = processingManager.processing;
    const isLayoutStep = processing.getExtra(Processing.EXTRA_STEP) === Processing.STEP_LAYOUT;

    const extraSelectedGroup = isLayoutStep
      ? Processing.SELECTED_LAYOUT_GROUP
      : Processing.SELECTED_RESULT_GROUP;

    processing.setExtra(extraSelectedGroup, tab);
    processingManager.update();

    this.setState({tab});
  }

  handleDownload = (creative) => {
    const fileUrl = resolveCreativeImageFile(creative, true);
    const fileName = fileUrl.substring(fileUrl.lastIndexOf('/')+1);

    if (window.clientConfig.isPro) {
      if (processingManager.processing.getExtra(Processing.EXTRA_PRO_WATERMARK_SHOULD_BE_REMOVED, false)) {
        hitEvent(hits.DOWNLOAD_WITHOUT_WATERMARK);
      } else {
        hitEvent(hits.DOWNLOAD_WITH_WATERMARK);
      }
    }

    if (window.clientConfig.isWebview) {
      const providers = [
        shareProvidersIds.save,
        shareProvidersIds.instagram,
        //shareProvidersIds.facebook,
        // shareProvidersIds.whatsapp,
        // shareProvidersIds.telegram,
        //shareProvidersIds.snapchat,
        //shareProvidersIds.tiktok,
        shareProvidersIds.allSystem,
      ];

      // if (window.clientConfig.isWebviewIOS) {
      //   providers.push(0,9);
      // } else if (window.clientConfig.isWebviewAndroid) {
      //   providers.push(0);
      //   this.context.installedApps.snapchat && providers.push(11);
      //   this.context.installedApps.instagram && providers.push(1);
      //   this.context.installedApps.facebook && providers.push(2);
      //   this.context.installedApps.whatsapp && providers.push(3);
      //   this.context.installedApps.facebookm && providers.push(5);
      //   this.context.installedApps.telegram && providers.push(6);
      // }

      const cbParams = {
        providers: "[" + providers.join(",") + "]",
        imageUrl: encodeURIComponent(fileUrl),
        hashtag: encodeURIComponent("#ArtBotAI"),
        autosave: 1,
      };

      webviewUtils.webviewShare(cbParams);

      if (this.isShowRateAppView()) {
        setTimeout(() => {
          this.context.pushModal(<RateAppModal key="ResultPage_RateAppModal" />);
        }, 1000);
      }

      return;
    }

    setTimeout(() => {
      saveAs(transformToDownloadUrl(fileUrl), fileName);

      this.context.pushModal(<DownloadModal key="ResultPage_DownloadModal" />);
    }, 100);
  }

  handleDownloadClick = (creative) => {
    webviewAnalyticsEvent("save_and_share",
      processingManager.processing.getExtra(Processing.EXTRA_TEXT),
      processingManager.processing.getExtra(Creative.EXTRA_AI_SEED),
      processingManager.processing.getExtra(Creative.EXTRA_TEXT_RESULT),
      processingManager.processing.getExtra(Creative.EXTRA_TEXT_APPEND, "")
    );

    // if (!processingManager.processing.getExtra(Processing.EXTRA_PRO_WATERMARK_SHOULD_BE_REMOVED, false)
    //   && !creative.getFile("raw_watermark"))
    // {
    //   creative.markAsPending();
    //   this.handleProcessingChanged();
    //
    //   Promise.race([
    //     creativeTimeoutPromise(5000),
    //     Promise.all([
    //       promisifyImage(transformToDownloadUrl(creative.getFile("raw")), true),
    //       promisifyImage(frontendWatermarkPreviewConfig.url, true),
    //     ])
    //   ])
    //     .then(([source, watermark]) => {
    //       const canvas = addWatermark(source, watermark, frontendWatermarkPreviewConfig);
    //       return new Promise((resolve) => canvas.toBlob(resolve, "image/jpeg"));
    //     })
    //     .then((blob) => api.tempImagesUploadFile(blob, "jpeg"))
    //     .then((fileUrl) => {
    //       creative.setFile("raw_watermark", fileUrl);
    //     })
    //     .then(() => {
    //       creative.markAsProcessed(
    //         creative.getFile("raw_watermark") || creative.getFile("raw")
    //       );
    //       processingManager.update();
    //       this.handleDownload(creative);
    //     })
    //     .catch((error) => {
    //       creative.markAsProcessed(
    //         creative.getFile("raw_watermark") || creative.getFile("raw")
    //       );
    //       processingManager.update();
    //       this.handleDownload(creative);
    //     });
    //
    //   return;
    // }

    this.handleDownload(creative);
  }

  isShowRateAppView = () => {
    return false;

    // eslint-disable-next-line no-unreachable
    if (window.clientConfig.isWeb) {
      return false;
    }

    if (window.clientConfig.webviewParams.app_id !== "80") {
      return false;
    }

    const isShowDialog = (Math.random() <= window.appConfig.webview.rateAppDialogPercent)
      && (clientStorage.getRateAppRequestLastShow() <= Date.now() - 86400000)
      && !clientStorage.getRateAppRequestIsRate()
      && !clientStorage.getRateAppRequestIsSkip();

    if (isShowDialog) {
      hitEvent(hits.RATEAPP_DIALOG_SHOW);
      logEvent(userEvents.RATEAPP_DIALOG_SHOW);

      clientStorage.setRateAppRequestLastShow(Date.now());
    }

    return isShowDialog;
  };

  handleStartClick = () => {
    this.context.showLoader(true);

    processingManager.clear();
    this.props.history.replace(generatePath(routes.INDEX), {view: "search"});

    return;
  }

  handleBackClick = () => {
    this.context.showLoader(true);

    const processing = processingManager.processing;
    processing.setExtra(Processing.EXTRA_SELFIE_MESSAGE_FILE, null);
    processingManager.update();

    goToProcessing(
      this.props.history,
      Processing.STEP_LAYOUT,
      null,
      null,
      processing.getExtra(Processing.EXTRA_TEXT, ""),
    );
  }

  handleForwardClick = () => {
    this.context.showLoader(true);

    const processing = processingManager.processing;

    goToProcessing(
      this.props.history,
      Processing.STEP_RESULT_WITHOUT_FILE,
      null,
      processing.layoutFile,
      processing.getExtra(Processing.EXTRA_TEXT, ""),
    );
  }

  handleFileSelected = (file, isRecent = false) => {
    const processing = processingManager.processing;
    processing.setExtra(Processing.EXTRA_PROCESSING_FILE, null);
    processingManager.update();

    logEvent(userEvents.PHOTO_SELECT, {
      page: "result",
      is_recent: isRecent,
    });

    this.context.showLoader(true, null, null, () => {
      this.props.history.replace(routes.UPLOAD, {file});
    });
  }

  render() {
    if (!this.context.loader.isHidden || !this.state.isReady) {
      return <React.Fragment />;
    }

    const processing = processingManager.processing;

    const pageClassNames = ["result-page"];
    const isShowTabs = this.state.tabs.length > 1;
    const isLayoutStep = processing.getExtra(Processing.EXTRA_STEP) === Processing.STEP_LAYOUT;

    return <main className={pageClassNames.join(" ")}>
      {isLayoutStep && <p className="result-title" dangerouslySetInnerHTML={{__html: i18n.t("result__select_result")}} />}

      {isShowTabs && <div className="tab-container">
        {!isLayoutStep &&
          <button className="btn-back" onClick={this.handleBackClick}>
            <IconBack />
          </button>
        }
        <div className="tabs-container">
          {this.state.tabs.map((tab, index) => <TabView
            key={index}
            group={tab}
            isActive={this.state.tab === tab}
            onClick={() => this.handleTabClick(tab)}
          />)}
        </div>
      </div>}

      {this.state.tab && this.renderTabContentView()}
    </main>;
  }

  renderTabContentView = () => {
    return <TabContentView
      key={this.state.tab}
      group={this.state.tab}
      onProcessingChanged={this.handleProcessingChanged}
      onDownloadClick={this.handleDownloadClick}
      onStartClick={this.handleStartClick}
      onForwardClick={this.handleForwardClick}
      onFileSelected={this.handleFileSelected}
    />;
  };
}

ResultPage.contextType = AppContext;

function hitFirstTabClick(processing, tab, hit = true) {
  const cacheKey = `tab_first_click:${processing.id}_${tab}`;

  if (!window.appGlobalCache[cacheKey]) {
    window.appGlobalCache[cacheKey] = true;
    if (hit) {
      hitEvent(hits.FIRST_CLICK_TAB);
    }
  }
}

function IconBack() {
  return <svg viewBox="0 0 16 16" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M16 7H3.8l5.6-5.6L8 0 0 8l8 8 1.4-1.4L3.8 9H16V7z" fill="#B7FFCF"/>
  </svg>;
}
